import React from 'react';
import { animated, useSpring, config } from 'react-spring';

const DragHover = () => {
  const trans = (x, y) => `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`;
  const [{ xy }, setPos] = useSpring(() => ({
    xy: [0, 0],
    config: config.slow
  }));
  const [{ o }, setOpacity] = useSpring(() => ({
    o: 0,
    config: config.slow
  }));
  return (
    <div
      className="absolute top-0 left-0 vw-100 h-100 z-max drag"
      onMouseMove={e => setPos({ xy: [e.clientX, e.clientY] })}
      onMouseEnter={() => setOpacity({ o: 0.8 })}
      onMouseLeave={() => setOpacity({ o: 0 })}
      onMouseDown={() => setOpacity({ o: 0 })}
      onMouseUp={() => setOpacity({ o: 0.8 })}
      style={{
        cursor: 'grab'
      }}
    >
      <animated.div
        className="fixed top-0 left-0"
        style={{
          transform: xy.interpolate(trans),
          opacity: o.interpolate(o => o)
        }}
      >
        <div
          className="bg-gold white f4 pv2 tc w4 br5"
          style={{
            transform: 'translate3d(0,-30px,0)'
          }}
        >
          Drag
        </div>
      </animated.div>
    </div>
  );
};

export default DragHover;

import React from 'react';
import ImagesLoaded from 'react-images-loaded';
import DragHover from './DragHover';
import ScrollBooster from 'scrollbooster';
import '../css/belt-dragger.css';

import { PrismicImage } from './common/prismicUtils';

class BeltDragger extends React.Component {
  handleDone = (instance) => {
    if (this.content && this.viewport) {
      const viewport = this.viewport;
      const content = this.content;
      new ScrollBooster({
        viewport, // this parameter is required
        content, // scrollable element
        mode: 'x', // scroll only in horizontal dimension
        onUpdate: (data) => {
          // your scroll logic goes here
          content.style.transform = `translateX(${-data.position.x}px)`;
        },
      });
    }
  };

  render() {
    const { images } = this.props;
    return (
      <ImagesLoaded done={this.handleDone}>
        <div
          className='belt-dragger-wrap mt5 mb5 mt6-l mb6-l w-100 overflow-hidden relative'
          ref={(v) => (this.viewport = v)}
        >
          <DragHover />
          <div className='belt-dragger-canvas' ref={(c) => (this.content = c)}>
            <div className='inline-flex w-auto items-center'>
              {images.map((item) => (
                <div
                  className={`belt-item ${
                    item?.image?.fluid?.aspectRatio < 1
                      ? 'portrait'
                      : 'landscape'
                  }`}
                >
                  <PrismicImage {...item.image} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </ImagesLoaded>
    );
  }
}

export default BeltDragger;
